<script>
// import axios from "axios";
// import { SimpleBar } from "simplebar-vue3";

import $cookies from "vue-cookies";
import { authComputed } from "@/state/helpers";
import { mapGetters } from "vuex/dist/vuex.cjs.js";
import "@/assets/css/nav-bar.css";
import { mapActions} from "vuex";
import Swal from 'sweetalert2';

/**
 * Nav-bar Component
 */
export default {
	data() {
		return {
			lang: "en",
			text: null,
			flag: null,
			value: null,
			search_modal:false,
			search_user: null,
			data: {
				list: [],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
			languages: [
				{
					flag: require("@/assets/images/flags/us.jpg"),
					language: "en",
					title: "English",
				},
				{
					flag: require("@/assets/images/flags/french.jpg"),
					language: "fr",
					title: "French",
				},
				{
					flag: require("@/assets/images/flags/spain.jpg"),
					language: "es",
					title: "Spanish",
				},
				{
					flag: require("@/assets/images/flags/chaina.png"),
					language: "zh",
					title: "Chinese",
				},
				{
					flag: require("@/assets/images/flags/arabic.png"),
					language: "ar",
					title: "Arabic",
				},
			],
			image_api: process.env.VUE_APP_BACKEND_API_IMAGES
		};
	},
	// components: { SimpleBar },
	mounted() {
		this.initList(1);
	},
	methods: {
		...mapActions("users", {
			iList: "getList",
		}),
		toggleMenu() {
			this.$parent.toggleMenu();
		},
		toggleRightSidebar() {
			this.$parent.toggleRightSidebar();
		},
		initFullScreen() {
			document.body.classList.toggle("fullscreen-enable");
			if (
				!document.fullscreenElement &&
				/* alternative standard method */ !document.mozFullScreenElement &&
				!document.webkitFullscreenElement
			) {
				// current working methods
				if (document.documentElement.requestFullscreen) {
					document.documentElement.requestFullscreen();
				} else if (document.documentElement.mozRequestFullScreen) {
					document.documentElement.mozRequestFullScreen();
				} else if (document.documentElement.webkitRequestFullscreen) {
					document.documentElement.webkitRequestFullscreen(
						Element.ALLOW_KEYBOARD_INPUT
					);
				}
			} else {
				if (document.cancelFullScreen) {
					document.cancelFullScreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				}
			}
		},
		logout() {
			$cookies.remove("token");
			window.location.reload();
		},
		gotoProfile() {
			this.$router.push({path: "/profile"});
		},
		gotoUserProfile() {
			var id;
			const contains = this.data.list.some((element) => {
				if (element.username.toLowerCase() == this.search_user.toLowerCase()) {
					id = element._id
					return true;
				}
				return false;
			});
			if(contains){
				this.$router.push({
					path: "/profile",
					query:{
						user_id: id
					}
				});
				this.search_modal = false;
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}else{
				Swal.fire({
					title: "Failed",
					html: `${this.search_user} profile doesn't exists.`,
					type: "error",
					icon: "error",
					padding: "2em",
				});
			}
		},
		gotoHome() {
			// this.$router.push({path: "/"});
			window.location.href = '/'
		},
		gotoNotifications(){
			this.$router.push({path: "/notifications"});
		},
		async initList(p) {
			var pl = {
				page: p,
			};
			const data = await this.iList(pl);
			this.data.list = data.data;
			this.data.links = data.links;
			this.data.current_page = data.current_page;
			this.data.per_page = data.per_page;
			this.data.last_page = data.last_page;
		},
	},
	computed: {
		...authComputed,
        ...mapGetters('auth', ['profile']),
	},
};
</script>

<template>
	<header id="page-topbar">
		<div class="navbar-header">
			<div class="d-flex hide-header">
				<div class="kio-logo" style="cursor:pointer;" @click="gotoHome()">
					<h6>k</h6>
				</div>
				<div class="d-flex">
					<div class="search mx-1 mt-3 mb-3">
						<i class="bx bx-search"></i>
						<input
							type="text"
							placeholder="ex: user01"
							class="form-control"
							v-model="search_user"
							list="my-list-id"
							@keyup.enter="gotoUserProfile()"
						/>
						<datalist id="my-list-id">
							<option v-for="(data, i) in data.list" :key="i" :value="data.username">
								{{ data.username }}
							</option>
						</datalist>
					</div>
				</div>
				<div class="navigations m-3">
					<div class="box text-center mx-auto">
						<router-link to="/"><i class="bx bx-home"></i></router-link>
						<router-link to="/messages"><i class="bx bx-message"></i></router-link>
					</div>
				</div>
				<div class="right-buttons d-flex">
					<button class="dropdown-item text-dark" @click="gotoNotifications()" style="background:transparent;">
						<i class="bx bx-bell" style="font-size: 30px;"></i>
					</button>
					<b-dropdown
						right
						variant="black"
						toggle-class="header-item"
						menu-class="dropdown-menu-end"
					>
						<template v-slot:button-content>
                            <img v-if="!this.profile.image" 
                                height="40" width="40"
                                class="raidus-100"
                                src="@/assets/images/profiles/default.png" >
							<img
								v-if="this.profile.image"
								class="rounded-circle"
								:src="`${image_api}/${this.profile.image}`"
								height="35"
								width="35"
								alt="Header Avatar"
							/>
							<i
								class="mdi mdi-chevron-down d-none d-xl-inline-block"
							></i>
							<span class="d-none d-xl-inline-block ms-1">
								<!-- <div v-if="profile.first_name">
									{{ profile.first_name }}
								</div> -->
							</span>
						</template>
						<!-- item-->
						<button @click="gotoProfile()" class="dropdown-item text-dark">
							<i
								class="bx bx-user font-size-16 align-middle me-1"
							></i>
							{{ $t("navbar.dropdown.henry.list.profile") }}
						</button>
						<button @click="logout()" class="dropdown-item text-danger">
							<i
								class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
							></i>
							{{ $t("navbar.dropdown.henry.list.logout") }}
						</button>
					</b-dropdown>
				</div>
			</div>
			<div class="responsive-header">
				<img class="kio-word" src="@/assets/images/kio-word.png" @click="gotoHome()">
				<div class="search-icon">
					<i class="bx bx-search px-1 fw-bold" @click="search_modal = true"></i>
				</div>
			</div>
			<!-- MODALS -->
			<b-modal 
				v-model="search_modal" 
				no-close-on-backdrop
				hide-footer
				size="sm"
				title="Search" 
				centered
				>
				<div class="d-flex mb-3">
					<div class="search-responsive w-100">
						<i class="bx bx-search"></i>
						<input
							type="text"
							placeholder="ex: user01"
							class="form-control"
							v-model="search_user"
							list="my-list-id"
							@keyup.enter="gotoUserProfile()"
						/>
						<datalist id="my-list-id">
							<option v-for="(data, i) in data.list" :key="i" :value="data.username">
								{{ data.username }}
							</option>
						</datalist>
					</div>
				</div>
				<div class="text-end">
					<button class="btn btn-info btn-sm p-2" @click="gotoUserProfile()">Search</button>
				</div>
				<!-- <h5>Recent Searches</h5>
				<div>
					<div class="d-flex p-3 recent-searches-button" v-for="data,i in 5" :key="i">
						<i class="bx bx-time-five fs-4"></i>
						<span class="mx-2">Bags</span>
					</div>
				</div> -->
			</b-modal>
		</div>
	</header>
</template>
<style>
.responsive-header{
	display: none;
}
.kio-word{
	height: 35px;
	margin-left: 10px;
}
.search-icon{
	height: 35px;
	width: 35px;
	background: #eceef1;
	border-radius: 50px;
	position: fixed;
	right: 10px;
}
.search-icon > i{
	font-size: 22px;
	margin-top: 5px;
	margin-left: 2px;
}

.search-responsive > i{
	position: absolute;
	z-index: 1;
	top: 24px;
	margin-left: 17px;
	font-size: 19px;
}
.search-responsive > input:focus{
	background: #F0F2F5;
}
.search-responsive > input{
	position: relative;
	height: 40px;
	width: 100%;
	border-radius: 100px;
	padding-left: 40px;
	margin-left: 5px;
	border: none;
	background: #F0F2F5;
	font-size: 14px;
}
@media (max-width:500px) {
	.hide-header{
		display: none !important;
	}
	.responsive-header{
		display: flex;
		justify-content: center;
	}
}
</style>
